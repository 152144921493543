import React from 'react';
import styled from 'styled-components';
import logoSmall from './logo192.png';
import text from './text.png';
import asciiArt from './ascii-art.png';
import Terminal from './terminal';
import CookieConsent from 'react-cookie-consent';
import { Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import lirLogo from './lir-150x150.png';
import tukilinjaLogo from './tukilinja-150px.png';

export const lineBreakRegExp = /(?:\r\n|\r|\n)/g;

const titleLeftMargin = 20;

const StyledApplication = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  @media only screen and (max-width: 600px) {
    margin-bottom: 1em;
  }
`;

const Layout = styled('div')`
  display: flex;
  margin-top: 2em;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 1em;
  }
`;

const TerminalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  & > img {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 1em;
  }
`;

const PictureContainer = styled('div')`
  display: flex;
`;

const TitleContainer = styled('div')`
  display: flex;
  margin-bottom: 1em;
  & > div {
    margin: 15px 0 0 ${titleLeftMargin}px;
  }
`;

const Link = styled('a')`
  color: white;
`;

const StaticContent = styled('div')`
  max-width: 400px;
  @media only screen and (max-width: 600px) {
    max-width: 335px;
  }
`;

const Footer = styled('div')`
  width: 800px;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  @media only screen and (max-width: 600px) {
    width: 335px;
    flex-direction: column;
  }
`;

const PhilantrophyContainer = styled('div')`
  margin: 2em 0 0 2em;
  display: flex;
  flex-wrap: wrap;
`;

const PhilantrophyImg = styled('img')``;

const Application = () => {
  const [staticContent, setStaticContent] = React.useState<JSX.Element[]>([]);
  React.useEffect(() => {
    if (window.location.pathname.includes('/static')) {
      loadStaticContent();
    }
  }, []);
  const blockWidth = isMobile ? 335 : 400;
  const logo = logoSmall;
  const logoSize = isMobile ? 55 : 60;
  const titleWidth = isMobile ? 335 - logoSize - titleLeftMargin : 400 - logoSize - titleLeftMargin;
  const renderAsciiArt = () => <img src={asciiArt} width={blockWidth} height={blockWidth} alt="ascii-art.png" />;
  const renderLogo = () => (
    <TitleContainer>
      <img src={logo} height={logoSize} width={logoSize} alt="logo.png" />
      <div>
        <img src={text} width={titleWidth} alt="text.png" />
      </div>
    </TitleContainer>
  );
  const renderFooter = (linkName: string, linkPath: string) => (
    <Footer>
      <div>© Immutible Oy {new Date().getFullYear()}</div>
      <div>Business ID 3182707-8</div>
      <div>
        {linkName}&nbsp;
        <Link href={linkPath}>here</Link>
      </div>
    </Footer>
  );
  const loadStaticContent = () => {
    Promise.all([fetch('./who.txt'), fetch('./contact.txt'), fetch('./skills.txt'), fetch('./cv.txt')]).then(
      (responses: Response[]) => {
        Promise.all(responses.map((response: Response) => response.text())).then((fileContents: string[]) => {
          const elements: JSX.Element[] = fileContents.flatMap((fileContent: string) => {
            const blockOfElements: JSX.Element[] = fileContent
              .split(lineBreakRegExp)
              .map((line: string, index: number) =>
                line.includes('http') ? (
                  <div key={index}>
                    <Link href={line}>{line}</Link>
                  </div>
                ) : (
                  <div key={index}>{line}</div>
                ),
              );
            blockOfElements.push(<br />);
            return blockOfElements;
          });
          setStaticContent(elements.map((element: JSX.Element, index: number) => <div key={index}>{element}</div>));
        });
      },
    );
  };
  return (
    <StyledApplication>
      <CookieConsent
        style={{ fontWeight: 'bold' }}
        buttonText="Sure, go ahead!"
        buttonStyle={{ background: '#516be6ff', color: 'white', fontFamily: 'Courier', fontWeight: 'bold' }}
        enableDeclineButton
        declineButtonText="Please don't"
        declineButtonStyle={{ fontFamily: 'Courier', fontWeight: 'bold' }}
        flipButtons
        onAccept={() => {
          const w: any = window;
          w.dataLayer.push('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
          });
          w.location.reload();
        }}
        expires={7}
      >
        This website would like to use cookies to track page visits. What do you think?
      </CookieConsent>
      <Route exact path="/">
        <Layout>
          <PictureContainer>{renderAsciiArt()}</PictureContainer>
          <TerminalContainer>
            {renderLogo()}
            <Terminal />
          </TerminalContainer>
        </Layout>
        {renderFooter('Lost? See static site', '/static')}
        <PhilantrophyContainer>
          <PhilantrophyImg src={lirLogo} alt="Médecins Sans Frontières supporter 2022" />
          <PhilantrophyImg src={tukilinjaLogo} alt="Tukilinja ry yritysystävä" />
        </PhilantrophyContainer>
      </Route>
      <Route exact path="/static">
        <Layout>
          <PictureContainer>{renderAsciiArt()}</PictureContainer>
          <TerminalContainer>
            {renderLogo()}
            <StaticContent>{staticContent}</StaticContent>
          </TerminalContainer>
        </Layout>
        {renderFooter('Dynamic site is', '/')}
        <PhilantrophyContainer>
          <PhilantrophyImg src={lirLogo} alt="Médecins Sans Frontières supporter 2022" />
          <PhilantrophyImg src={tukilinjaLogo} alt="Tukilinja ry yritysystävä" />
        </PhilantrophyContainer>
      </Route>
    </StyledApplication>
  );
};

export default Application;
